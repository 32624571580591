import * as React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import Disclaimer from '../components/disclaimer/disclaimer'
import Menu from '../components/menu/menu'
import PopupNotifications from '../components/notifications/popupNotification/popupNotifications'
import PremiumDialog from '../components/informationWindow/premiumDialog'

import { Scrollbars } from 'react-custom-scrollbars-2'

import { PmCommonType, ScrolledPageAmountAndPositionType } from '../store/common/commonSliceTyping'
import { RootState } from '../store/rootReducer'
import ReactGA from 'react-ga4'

import {
    changeScrolledPosition,
    switchPageOnNotScrolled,
    switchPageOnScrolled,
    switchUserOnActive,
    switchUserOnNotActive,
    unfixAppContainer,
    setIsLeavingPage,
    pageScrolled
} from '../store/common/commonSlice'
import Cookies from 'js-cookie'
import { memberFetch } from '../store/member/memberSlice'

import { Helmet } from 'react-helmet'
import i18next, { getLangFromPath } from '../i18next'
// @ts-ignore
import { FullPmState } from 'pm-js/types/pm'

import './app.sass'
import './../styles/global.sass'
import passiveSupported from '../utils/passiveSupported'
import { useInstanoti } from '../hooks/useInstanoti'
import usePosition from '../hooks/usePosition'
import usePmRequest from '../hooks/usePmRequest'

// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') require('./development.sass')

const App: React.FunctionComponent = ({ children }) => {
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    useInstanoti()
    usePosition()
    usePmRequest()

    const isUserActive = useSelector<RootState, boolean>((state) => state.common.isUserActive)
    const isPageScrolled = useSelector<RootState, boolean>((state) => state.common.isPageScrolled)
    const isPageLoading = useSelector<RootState, boolean>((state) => state.common.isPageLoading)
    const isAppContainerFixed = useSelector<RootState, boolean>((state) => state.common.isAppContainerFixed)
    const isLeavingPage = useSelector<RootState, boolean>((state) => state.common.isLeavingPage)
    const swiperTopPosition = useSelector<RootState, number>((state) => state.common.swiperTopPosition)
    const scrollToTopBottom = useSelector<RootState, string>((state) => state.common.scrollToTopBottom)
    const scrolledPagesAmountAndPosition = useSelector<RootState, ScrolledPageAmountAndPositionType>(
        (state) => state.common.scrolledPagesAmountAndPosition
    )
    const isLoaderDisabled = useSelector<RootState, boolean>((state) => state.common.isLoaderDisabled)
    const pm_common = useSelector<RootState, PmCommonType>((state) => state.common.pm_common)

    const user = useSelector((state: RootState) => state.member.user)
    const loggedIn = useSelector((state: RootState) => state.member.loggedIn)
    const pm = useSelector<RootState, FullPmState>((state) => state.pm)

    const [dailyCookie, setDailyCookie] = useState(false)
    const [activityTimeout, setActivityTimeout] = useState(null)
    const [isFirstLoading, setIsFierstLoading] = useState(true)

    const ref = useRef(null)
    const firstLoadRef = useRef(null)
    // let dialog = null
    const root = typeof window !== 'undefined' ? document.getElementById('root') : null
    const isDailyCoockieInited = useRef(false)

    const isNecessaryToSaveThePosition = () => {
        const adressLastItem = location.pathname.split('/').reverse()[0]
        return (
            (adressLastItem === '' ||
                adressLastItem === 'membres' ||
                location.pathname.includes('tags') ||
                location.pathname.includes('photo') ||
                location.pathname.includes('video')) &&
            !location.pathname.includes('les-meilleures-photos')
        )
    }

    const scrollPreviousPosition = () => {
        if (isNecessaryToSaveThePosition) {
            ref.current.scrollTop(swiperTopPosition || scrolledPagesAmountAndPosition.scrollPosition)
        }
    }

    const beginningUserActivity = () => {
        setActivityTimeout(new Date())
        if (!isUserActive) dispatch(switchUserOnActive())
    }

    useEffect(() => {
        if (typeof window !== 'undefined' && isFirstLoading) {
            const loader = document.getElementById('globalLoader')
            if (loader)
                setTimeout(() => {
                    setIsFierstLoading(false)
                    loader.remove()
                }, 150)
        }
    }, [])

    useEffect(() => {
        root.addEventListener('click', beginningUserActivity)
        root.addEventListener('touchstart', beginningUserActivity, passiveSupported)
        return () => {
            root.removeEventListener('click', beginningUserActivity)
            root.removeEventListener('touchstart', beginningUserActivity)
        }
    }, [])

    useEffect(() => {
        if (!firstLoadRef.current && !user) {
            firstLoadRef.current = true
            dispatch(memberFetch({}))
        }
    }, [])
    // This useEffect is needed for authorization when opening app

    useEffect(() => {
        if (loggedIn) {
            dispatch({ type: 'PM_CONNECT_TO_SERVER', url: process.env.RAZZLE_PM_SERVER_URL, user: user })
        } else {
            // @ts-ignore
            if (pm.status == 'connected') {
                dispatch({ type: 'PM_DISCONNECT_FROM_SERVER' })
            }
        }
    }, [loggedIn])

    const timeoutRef = useRef(null)

    useEffect(() => {
        const connectPmServer = () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
                return
            }
            setTimeout(
                () => dispatch({ type: 'PM_CONNECT_TO_SERVER', url: process.env.RAZZLE_PM_SERVER_URL, user: user }),
                10
            )
        }

        const disconnectSeconds = 5 * 60

        const disconnectPmServer = () => {
            timeoutRef.current = setTimeout(() => {
                dispatch({ type: 'PM_DISCONNECT_FROM_SERVER' })
                console.log(`PM server disconnected by timeout ${disconnectSeconds}s`)
                timeoutRef.current = null
            }, disconnectSeconds * 1000)
        }

        if (loggedIn) {
            // This condition is necessary to prevent videoCall splitting chat when change language in active call.
            if (pm_common.state === 'active' || pm_common.state === 'connecting' || pm_common.state === 'connected')
                history.go(0)
            //
            if (pm.status == 'connected') {
                dispatch({ type: 'PM_DISCONNECT_FROM_SERVER' })
                setTimeout(
                    () => dispatch({ type: 'PM_CONNECT_TO_SERVER', url: process.env.RAZZLE_PM_SERVER_URL, user: user }),
                    10
                )
            }
            if (typeof window !== 'undefined') window.addEventListener('blur', disconnectPmServer)
            if (typeof window !== 'undefined') window.addEventListener('focus', connectPmServer)
        }
        return () => {
            if (typeof window !== 'undefined') window.removeEventListener('blur', disconnectPmServer)
            if (typeof window !== 'undefined') window.removeEventListener('focus', connectPmServer)
        }
    }, [i18next.language])

    useEffect(() => {
        switch (scrollToTopBottom) {
            case 'top':
                ref.current.scrollToTop()
                dispatch(pageScrolled())
                break
            case 'bottom':
                ref.current.scrollToBottom()
                dispatch(pageScrolled())
                break
            default:
                dispatch(pageScrolled())
                break
        }
    }, [scrollToTopBottom])

    useEffect(() => {
        const timeout = setTimeout(() => {
            dispatch(switchUserOnNotActive())
        }, 3000)
        return () => {
            clearTimeout(timeout)
        }
    }, [activityTimeout])

    useEffect(() => {
        dispatch(unfixAppContainer())
        // dialog = document.getElementById('dailyDialog')
        if (!Cookies.get('Visited') && !isDailyCoockieInited.current && !location.search.includes('nodisclaimer=1')) {
            isDailyCoockieInited.current = true
            setTimeout(() => setDailyCookie(true), 5000)
        }
        if (Cookies.get('Visited')) {
            setDailyCookie(false)
        } else {
            // if (!dialog) history.go(0)
            // @ts-ignore
            document.getElementById('root').style.overflowY = 'hidden'
        }
        // eslint-disable-next-line
    }, [location])

    useEffect(() => {
        if (!isPageLoading && isNecessaryToSaveThePosition()) {
            setTimeout(() => scrollPreviousPosition(), 200)
        }
    }, [isPageLoading])

    useEffect(() => {
        if (window) {
            ReactGA.initialize('UA-6283810-3')
        }
    }, [])

    useEffect(() => {
        if (window) {
            ReactGA.ga('send', 'pageview', location.pathname + location.search)
        }
    }, [location.pathname, location.search])

    return (
        <Scrollbars
            ref={ref}
            onScrollStart={() => beginningUserActivity()}
            onUpdate={(values) => {
                {
                    values.top == 0 && isPageScrolled
                        ? dispatch(switchPageOnNotScrolled())
                        : !isPageScrolled && values.top > 0.001 && dispatch(switchPageOnScrolled())
                }
                if (isLeavingPage) {
                    dispatch(setIsLeavingPage(false))
                    dispatch(
                        changeScrolledPosition({
                            scrollPosition: Math.ceil(values.top * values.scrollHeight) - values.clientHeight
                        })
                    )
                }
            }}
            universal
            style={{ width: '100%', height: '100vh' }}
            renderThumbVertical={(props) => <div {...props} style={{ background: '#cecece', borderRadius: '4px' }} />}
        >
            <Helmet>
                <html lang={getLangFromPath(location.pathname)} />
                {/*<link rel="canonical" href={location.pathname} />*/}
            </Helmet>
            <div
                className={`${
                    isAppContainerFixed || location.pathname.includes('/pm/')
                        ? 'app_container modalOpen'
                        : 'app_container'
                } ${isFirstLoading ? 'contentLoading' : ''}`}
            >
                {children}
                <Menu />
                <PopupNotifications />
                {isPageLoading && !isLoaderDisabled && (
                    <div className="loading">
                        <div className="lds-dual-ring"></div>
                    </div>
                )}
                <div className="popupDialogs">
                    <PremiumDialog />
                </div>
                {dailyCookie && <Disclaimer setDailyCookie={setDailyCookie} />}
            </div>
        </Scrollbars>
    )
}

export default App
